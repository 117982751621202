@tailwind base;
@tailwind components;
@tailwind utilities;

/* These styles will be purged */

@layer base {
  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/PlusJakartaSans-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
    font-style: normal;
    src: url("./assets/fonts/PlusJakartaSans-Medium.woff2") format("woff2");
  }

  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/PlusJakartaSans-SemiBold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/PlusJakartaSans-Bold.woff2") format("woff2");
  }
}
@layer components {
}
@layer utilities {
}

html, body {
  height: 100%;
  width: 100%;
}

/* These styles will not be purged */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* These are the custom styles written to override default theme styles */
.height_100vh {
  height: 100vh;
}

.error-message {
  color: red;
}

.app-gradient-text {
  background: #f03aa7; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f03aa7, #3C149F); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f03aa7, #3C149F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Fix for OTP verification input container */
.vi__wrapper, .vi__container {
  width: 100% !important;
}

.react-confirm-alert-body {
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
}

.dark .react-confirm-alert-overlay {
  background-color: rgba(17, 24, 39, 0.8); /* bg-gray-900 with 0.4 alpha */
}

.dark .react-confirm-alert-body {
  background-color: rgba(17, 24, 39, 1); /* bg-gray-900 */
  color: rgb(156, 163, 175);
  box-shadow: 0 20px 75px rgb(255 255 255 / 19%);
}

.dark .react-confirm-alert-button-group button {
  background-color: rgba(17, 24, 39, 0.4); /* bg-gray-900 */
  color: rgb(156, 163, 175, 1);
  border: 1px solid #fff;
}

.carousel.carousel-slider .control-arrow {
  padding: 20px;
}

.carousel.carousel-slider .control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 35px;
}

/* For chat input placeholder */
.editable:empty:before {
  content: attr(data-placeholder);
  opacity: 0.5;
}

/* To fix the text color of emoji search input in chat  */
.dark .emoji-picker-react input.emoji-search {
  color: #101827;
}
